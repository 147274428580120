import React, { useContext, useState } from 'react';
import _ from 'lodash';

import {
  Box,
  IconButton,
  SwipeableDrawer,
} from '@mui/material';
import { Remove } from '@mui/icons-material';

import QueueContext from './QueueContext';
import QueueItem from './QueueItem';
import QueueItemSummary from './QueueItem/QueueItemSummary';

const QueueProgress = () => {
  const { queues, ...values } = useContext(QueueContext);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  if (!(queues || []).length) {
    if (open) setOpen(false);
    if (expanded) setExpanded(false);
    return null;
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '& > .MuiPaper-root': {
          height: 'calc(50% - 58px)',
          overflow: 'visible',
          pointerEvents: 'auto !important',
          maxWidth: '600px',
          width: '100%',
          margin: '0 auto',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: open ? 0 : -58,
          visibility: 'visible',
          right: 0,
          left: 0,
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
          boxShadow: '0px -4px 6px -1px rgba(0,0,0,0.12)',
          bgcolor: 'background.paper',
        }}
      >
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setOpen(!open);
            if (expanded) setExpanded(false);
          }}
          sx={{
            p: '0px 4px',
            position: 'absolute',
            top: '-4px',
            left: 'calc(50% - 18px)',
            zIndex: 1,
            borderRadius: '10px',
          }}
        >
          <Remove sx={{ fontSize: 36 }} />
        </IconButton>

        {open ? null : (
          <Box
            onClick={() => {
              setOpen(!open);
              if (!open) setExpanded(true);
            }}
          >
            <QueueItemSummary queue={queues[0]} {...values} />
          </Box>
        )}
      </Box>

      <Box sx={{ overflow: 'auto' }}>
        {_.map(queues || [], (queue, k) => (
          <QueueItem
            {...values}
            queue={queue}
            key={queue.id}
            expanded={k === 0 ? expanded : false}
          />
        ))}
      </Box>
    </SwipeableDrawer>
  );
};

export default QueueProgress;
