import React from 'react';
import moment from 'moment/moment';

import { AccordionDetails, Button, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';

import SmsResult from '../../../../../routes/App/Sms/Components/SmsResultDialog/SmsResult';
import { Consumer } from '../../../../Container/Language/TranslationsContext';

const isOld = (started) => !started || moment(new Date()).diff(moment(started)) > 1800000; // 30 min

const CancelBtn = ({ queue, dropQueue }) => (queue.percent < 100 && isOld(queue.started) ? (
  <IconButton size="small" onClick={() => dropQueue(queue.id)} sx={{ position: 'absolute', right: 15 }}>
    <Cancel />
  </IconButton>
) : null);

const QueueItemDetails = ({ queue, dropQueue }) => (
  <AccordionDetails>
    <CancelBtn queue={queue} dropQueue={dropQueue} />
    <Consumer>
      {({ t }) => {
        switch (queue.type) {
          case 'campaign': return (<SmsResult {...queue} />);
          case 'bulkExport': return (queue.percent < 100 ? queue.text : (
            <Button href={`/account/downloadReport/id/${queue.id}/`} target="_blank" size="small">
              {t('app:common:download')}
            </Button>
          ));
          case 'schedulerRemove':
          case 'bulkRemove':
            return queue.percent < 100
              ? (queue.text || '')
                .replace('Поиск смс для удаления...', t('app:controller:report:popup:waiting:find'))
                .replace('Идет удаление смс...', t('app:controller:report:popup:waiting:removing'))
                .replace('Обновление баланса...', t('app:controller:report:popup:waiting:balance'))
              : t('app:controller:report:popup:waiting:success');
          case 'deliveredSave':
            return queue.percent < 100
              ? (queue.text || '')
                .replace('Подготовка рабочих номеров к сохранению...', t('app:controller:report:popup:waiting:prepare'))
                .replace('Сохранение списка...', t('app:controller:report:popup:waiting:saving'))
              : t('app:controller:report:param:delivered:saved');
          default:
            return queue.text;
        }
      }}
    </Consumer>
  </AccordionDetails>
);

export default QueueItemDetails;
