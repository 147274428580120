import React, { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';

import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AuthContext from '../../../HO/Auth/AuthContext';
import { FormConsumer, FormProvider } from '../../../HO/Form';
import { LanguagesConsumer, LanguagesProvider } from '../../../Container/Language';
import TranslationsContext from '../../../Container/Language/TranslationsContext';

import de from '../../../../assets/img/languages/de.svg';
import en from '../../../../assets/img/languages/en.svg';
import es from '../../../../assets/img/languages/es.svg';
import ge from '../../../../assets/img/languages/ge.svg';
import inIcon from '../../../../assets/img/languages/in.svg';
import pt from '../../../../assets/img/languages/pt.svg';
import ru from '../../../../assets/img/languages/empty.svg';
import sl from '../../../../assets/img/languages/sl.svg';
import uk from '../../../../assets/img/languages/uk.svg';

const langs = {
  de, en, es, ge, in: inIcon, pt, ru, sl, uk,
};

const LangMenu = ({
  data, dataLoading, setForm, form,
  sendForm, formErrors, formData, formLoading,
}) => {
  const theme = useTheme();
  const isDownThanSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { form: { language }, setForm: setTrans } = useContext(TranslationsContext);
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClick = (event) => {
    if (!form) setForm({ /* site: true */ }); // all active
    setAnchorEl(event.currentTarget);
  };
  const setLang = (code) => {
    if (user) {
      sendForm({ language: code });
    } else {
      setAnchorEl(null);
      setTrans({ language: code });
    }
  };

  useEffect(() => {
    if (formErrors) {
      //
    } else if (formData && formData.data) {
      setTrans({ language: formData.data });
    }
  }, [formErrors, formData, setTrans]);

  useEffect(() => {
    if (formLoading) {
      setAnchorEl(null);
    }
  }, [formLoading]);

  return (
    <>
      <IconButton
        disabled={dataLoading || formLoading}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onClick}
        sx={{ ml: 1 }}
      >
        {dataLoading ? (<CircularProgress size={24} />) : (
          <>
            <img alt={language} src={langs[language]} height={16} />
            {isDownThanSM ? null : (<Typography variant="button" sx={{ ml: 0.5 }}>{language}</Typography>)}
          </>
        )}
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open && Boolean((data || []).length)}
        onClose={() => setAnchorEl(null)}
      >
        {_.map(data || [], (l) => (l.code === language ? null : (
          <MenuItem key={v4()} onClick={() => setLang(l.code.toLowerCase())}>
            <img alt={l.code} src={langs[l.code.toLowerCase()]} height={16} />
            <Typography variant="button" sx={{ ml: 0.5 }}>{l.code}</Typography>
          </MenuItem>
        )))}
      </Menu>
    </>
  );
};

const LangMenuContext = (props) => (
  <FormProvider formName="setLang">
    <FormConsumer>
      {(formState) => (
        <LanguagesProvider form={null}>
          <LanguagesConsumer>
            {(state) => (<LangMenu {...props} {...formState} {...state} />)}
          </LanguagesConsumer>
        </LanguagesProvider>
      )}
    </FormConsumer>
  </FormProvider>
);

export default LangMenuContext;
