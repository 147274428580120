import React, { useContext, useEffect, useRef } from 'react';

import { CircularProgress } from '@mui/material';

import AuthContext from '../../HO/Auth/AuthContext';
import ConfigsContext from '../../Container/Config/ConfigsContext';
import { TranslationsProvider } from '../../Container/Language';
import Layout from './Layout';
import Gtag from '../../Functional/Gtag';

const LayoutContext = (props) => {
  const { user } = useContext(AuthContext);
  const { data, dataLoading } = useContext(ConfigsContext);
  const isConfigsMount = useRef(false);

  useEffect(() => {
    if (data && !isConfigsMount.current) {
      isConfigsMount.current = true;

      if (data.GA_ID) {
        try {
          import('react-ga').then((ReactGA) => {
            ReactGA.initialize(data.GA_ID);
            // move pageview to other useEffect ? after routed ?
            ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
          });
        } catch (e) {
          console.log(e);
        }
      }

      // if (data.GTM_ID && !user) {
      //   try {
      //     import('react-gtm-module').then((TagManager) => {
      //       TagManager.initialize({
      //         gtmId: data.GTM_ID,
      //       });
      //     });
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }

      if (data.FBT_ID) {
        try {
          import('react-facebook-pixel').then(({ default: ReactPixel }) => {
            ReactPixel.init(data.FBT_ID);
            // For tracking page view
            ReactPixel.pageView();
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data || dataLoading) {
    return (
      <div style={{ margin: '25px auto', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <TranslationsProvider form={{ language: user ? user.language : data.language }}>
      {data.GTM_ID && !user ? <Gtag id={data.GTM_ID} /> : null}
      <Layout {...props} />
    </TranslationsProvider>
  );
};

export default LayoutContext;
